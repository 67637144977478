.tiptap {
  background-color: #212121; /* grey[900] */
  color: #fafafa; /* This sets the text color to grey[50] */
  height: 60vh;
  overflow-y: auto; /* Allows scrolling within the editor when the content exceeds max-height */
  padding: 8px; /* Optional: To match MUI TextField padding */
  border: 1px solid #ced4da; /* Optional: To match MUI TextField border */
  border-radius: 4px; /* Optional: To match MUI TextField border radius */
}

.tiptap:focus {
  outline: 2px solid #757575; /* A darker shade for better visibility on grey[800] */
  box-shadow: none;
}

.tiptap ul,
.tiptap ol {
  padding: 0 1rem;
}

.tiptap h1,
.tiptap h2,
.tiptap h3,
.tiptap h4,
.tiptap h5,
.tiptap h6 {
  line-height: 1.1;
}

.tiptap code {
  background-color: #fff;
  color: black;
  padding: 2px 4px; /* Add padding to the code element */
}

.tiptap pre {
  background: #0d0d0d;
  color: #fff;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.tiptap pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}

.tiptap img {
  width: 100%;
  max-height: 250px;
  object-fit: contain;
}

.tiptap img.ProseMirror-selectednode {
  outline: 1px solid #ced4da;
}

.tiptap blockquote {
  padding-left: 1rem;
  border-left: 2px solid #eeeeee;
}

.tiptap hr {
  border: none;
  border-top: 2px solid #ced4da;
  margin: 2rem 0;
}
