html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
* {
  font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
}
